<template>
  <div>
    <div class="page-title">
      <h3>User Management</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li><a href="#">Management</a></li>
          <li class="active">
            Users
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Users List
              </h4>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="usersList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile Number</th>
                      <th>Gender</th>
                      <th>Title</th>
                      <th>Supervisor</th>
                      <th>Branch</th>
                      <th>Products</th>
                      <th>Last Online</th>
                      <th>Is Enabled?</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="userDetailsModal"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span
                  aria-hidden="true"
                >&times;</span>
              </button>
              <h2 class="modal-title text-center">
                User Details
              </h2>
            </div>
            <div class="modal-body">
              <div
                class="row"
                style="margin-top: 25px"
              >
                <div class="col-sm-12">
                  <div
                    class="row"
                    style="margin-top: 25px"
                  >
                    <div class="form-group col-sm-offset-1 col-sm-4">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="userDetails.jobTitle"
                          :allow-empty="false"
                          :class="{ 'placeholder-shown': (!userDetails.jobTitle || userDetails.jobTitle === '') }"
                          :options="_getTitles"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">{{ $Amplify.I18n.get('Job Title') }}</span>
                      </label>
                    </div>
                    <div class="form-group col-sm-offset-2 col-sm-4">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="userDetails.supervisor"
                          :allow-empty="false"
                          :class="{ 'placeholder-shown': (!userDetails.supervisor || userDetails.supervisor === '') }"
                          :custom-label="getSupervisorName"
                          :options="getSupervisorsList"
                          :placeholder="null"
                          :show-labels="false"
                          track-by="id"
                        />
                        <span class="float-label">{{ $Amplify.I18n.get('Supervisor') }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-offset-1 col-sm-4">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="userDetails.branchId"
                          :allow-empty="false"
                          :class="{ 'placeholder-shown': (!userDetails.branchId || userDetails.branchId === '') }"
                          :multiple="true"
                          :options="getEnabledBranches"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">{{ $Amplify.I18n.get('Branch') }}</span>
                      </label>
                    </div>
                    <div class="form-group col-sm-offset-2 col-sm-4">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="userDetails.products"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!userDetails.products || userDetails.products === '') }"
                          :multiple="true"
                          :options="getEnabledProducts"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">{{ $Amplify.I18n.get('Products') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-b-lg">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="form-group col-sm-offset-1 col-sm-3">
                      <label class="form-details-label">First Name:</label>
                      <p class="text-muted">
                        {{ userDetails.firstName }}
                      </p>
                    </div>
                    <div class="form-group col-sm-offset-1 col-sm-3">
                      <label class="form-details-label">Last Name:</label>
                      <p class="text-muted">
                        {{ userDetails.lastName }}
                      </p>
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-details-label">Nickname:</label>
                      <p class="text-muted">
                        {{ userDetails.nickname }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-offset-1 col-sm-3">
                      <label class="form-details-label">Preferred Username:</label>
                      <p class="text-muted">
                        {{ userDetails.preferredUsername }}
                      </p>
                    </div>
                    <div class="form-group col-sm-2">
                      <label class="form-details-label">Username:</label>
                      <p class="text-muted">
                        {{ userDetails.username }}
                      </p>
                    </div>
                    <div class="form-group col-sm-2">
                      <label class="form-details-label">Date Of Birth:</label>
                      <p class="text-muted">
                        {{ userDetails.birthDate }}
                      </p>
                    </div>
                    <div class="form-group col-sm-2">
                      <label class="form-details-label">Gender:</label>
                      <p class="text-muted">
                        {{ userDetails.gender }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-offset-1 col-sm-2">
                      <label class="form-details-label">Email Address:</label>
                      <p class="text-muted">
                        {{ userDetails.email }}
                      </p>
                    </div>
                    <div class="form-group col-sm-offset-1 col-sm-2">
                      <label class="form-details-label">Is Email Verified?</label>
                      <p class="text-muted">
                        {{ userDetails.emailVerified }}
                      </p>
                    </div>
                    <div class="form-group col-sm-2">
                      <label class="form-details-label">Mobile Number:</label>
                      <p class="text-muted">
                        {{ userDetails.phone }}
                      </p>
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-details-label">Is Mobile Number Verified:</label>
                      <p class="text-muted">
                        {{ userDetails.phoneVerified }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-offset-2 col-sm-10">
                      <label class="form-details-label">Unique Customer Link:</label>
                      <a
                        :href="userDetails.website"
                        target="_blank"
                      >{{ userDetails.website }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="userDetails.isEnabled"
                id="disableButton"
                class="btn btn-default btn-warning pull-left"
                type="button"
                @click.stop.prevent="disableCurrentUser"
              >
                Disable User
              </button>
              <button
                v-if="!userDetails.isEnabled"
                id="enableButton"
                class="btn btn-default btn-success pull-left"
                type="button"
                @click.stop.prevent="enableCurrentUser"
              >
                Enable User
              </button>
              <button
                class="btn btn-default"
                data-dismiss="modal"
                type="button"
              >
                Close
              </button>
              <button
                id="updateButton"
                class="btn btn-default btn-info"
                type="button"
                @click.stop.prevent="updateCurrentUser"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Main Wrapper -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscriptions from "../../graphql/subscriptions";
import VueMultiselect from "vue-multiselect";

export default {
  name: "UserManagement",
  components: {
    VueMultiselect
  },
  data() {
    return {
      users: [],
      statuses: [],
      userTable: null,
      userDetails: {
        title: '',
        supervisor: '',
        branchId: '',
        products: ''
      },
      supervisors: [],
      branchManagers: [],
      associatePartners: [],
      createUserSubscription: null,
      updateUserSubscription: null
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getTitles",
      "getBranches",
      "getProducts"
    ]),
    _getTitles: function () {
      return _.sortBy(this.getTitles, 'name');
    },
    getEnabledBranches: function () {
      return _.sortBy(_.filter(this.getBranches, (branch) => {
        return branch.isEnabled;
      }), 'name');
    },
    getEnabledProducts: function () {
      return _.sortBy(_.filter(this.getProducts, (product) => {
        return product.isEnabled;
      }), 'name');
    },
    getSupervisorsList: function () {
      let supervisorsList = [];

      let supervisors = this.getTitleUsers('Supervisor');
      let managers = this.getTitleUsers('Manager');
      let partners = this.getTitleUsers('Partner');
      let owners = this.getTitleUsers('Owner');

      return _.sortBy(_.concat(supervisorsList,
          supervisors,
          managers,
          partners,
          owners
      ), 'username');
    }
  },
  async beforeMount() {
    const {
      data: {listUsers}
    } = await API.graphql(graphqlOperation(queries.listUsers, {limit: 9999}));
    this.users = listUsers.items;

    this.createDatatable();
    this.unsubscribeLists();

    this.createUserSubscription = API.graphql(graphqlOperation(subscriptions.onCreateUser
    )).subscribe({
      next: (update) => {
        let user = update.value.data.onCreateUser;
        this.userTable.row
            .add(user)
            .draw();
      }
    });

    //TODO: SAME EVENT GETS THROWN MULTIPLE TIMES, ALSO THIS SHOULDN'T TRIGGER IF THE ACCOUNT WAS UPDATED BY THE ADMIN
    // this.updateUserSubscription = API.graphql(graphqlOperation(subscriptions.onUpdateUser
    // )).subscribe({
    // 	next: (update) => {
    // 		let user = update.value.data.onUpdateUser;
    // 		this.getBase64ImageString(user, 0)
    // 			.then((result) => {
    // 				user.picture = result.picture;
    // 				this.userTable.row("#" + user.id)
    // 					.invalidate()
    // 					.data(user)
    // 					.draw();
    // 				this.setMessage('v-notify-info', "Notification", user.username + " updated their profile!");
    // 			})
    // 			.then(e => {
    //
    // 			});
    // 	}
    // });
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');
  },
  beforeDestroy() {
    this.unsubscribeLists();
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    unsubscribeLists: function () {
      if (this.createUserSubscription) {
        this.createUserSubscription.unsubscribe();
      }
      if (this.updateUserSubscription) {
        this.updateUserSubscription.unsubscribe();
      }
    },
    getTitleUsers: function (titleName) {
      let usersList = _.result(_.find(this.getTitles, (title) => {
        return title.access === titleName;
      }), 'users');
      if (usersList) {
        usersList = _.filter(this.users, (user) => {
          return _.includes(usersList, user.id);
        });
        return usersList;
      } else {
        return [];
      }
    },
    getSupervisorName: function (user) {
      if (user) {
        let name = _.capitalize(user.firstName);

        if (user.lastName) {
          name += " " + _.capitalize(user.lastName);
        }
        return name;
      } else {
        return '';
      }
    },
    getBase64ImageString: function (user, userIndex) {
      return new Promise((resolve) => {
        if (user.picture && user.picture !== '') {
          Storage.get(user.picture, {
            level: 'protected',
            identityId: user.identityId,
            download: true
          }).then((image) => {
            if (image.Body && image.Body !== '') {
              let binary = '';
              let len = image.Body.byteLength;
              for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(image.Body[i]);
              }
              resolve({index: userIndex, picture: "data:image/png;base64," + window.btoa(binary)});
            } else {
              resolve({index: userIndex, picture: require('../../assets/user.png')});
            }
          }).catch(() => {
            this.setMessage('v-notify-error', "Error!", "Unable to fetch profile picture for " + user.username);
            resolve({index: userIndex, picture: require('../../assets/user.png')});
          })
        } else {
          resolve({index: userIndex, picture: require('../../assets/user.png')});
        }
      });
    },
    createDatatable: function () {
      this.userTable = $('#usersList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: _.filter(this.users, (user) => {
          return this.getLoggedInUser ? user.jobTitle !== 'cd008c3e-2bb5-4add-87b7-a6dcd717c1a2' && user.id !== this.getLoggedInUser.id : user.jobTitle !== 'cd008c3e-2bb5-4add-87b7-a6dcd717c1a2';
        }),
        rowId: 'id',
        columns: [
          {
            data: 'username'
          },
          {
            data: 'firstName',
            defaultContent: ""
          },
          {
            data: 'email',
            defaultContent: ""
          },
          {
            data: 'phone',
            defaultContent: ""
          },
          {
            data: 'gender',
            defaultContent: ""
          },
          {
            data: 'jobTitle',
            defaultContent: ""
          },
          {
            data: 'supervisor',
            defaultContent: ""
          },
          {
            data: 'branchId',
            defaultContent: ""
          },
          {
            data: 'products',
            defaultContent: ""
          },
          {
            data: 'lastOnline',
            defaultContent: ""
          },
          {
            data: 'isEnabled',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data, type, row) => {
              if (row.lastName) {
                return data + ' ' + row.lastName;
              } else {
                return data;
              }
            },
            targets: 1
          },
          {
            render: (data) => {
              if (_.isObjectLike(data)) {
                return data.name;
              } else {
                if (data && data !== '') {
                  let userJobTitle = _.find(this.getTitles, (title) => {
                    return title.id === data;
                  });
                  if (userJobTitle) {
                    return userJobTitle.name;
                  } else {
                    return data;
                  }
                } else {
                  return '';
                }
              }
            },
            targets: 5
          },
          {
            render: (data) => {
              if (_.isObjectLike(data)) {
                return this.getSupervisorName(data);
              } else {
                if (data && data !== '') {
                  let userSupervisor = _.find(this.users, (user) => {
                    return user.id === data;
                  });
                  if (userSupervisor) {
                    return this.getSupervisorName(userSupervisor);
                  } else {
                    return data;
                  }
                } else {
                  return '';
                }
              }
            },
            targets: 6
          },
          {
            render: (data) => {
              if (data && data.length > 0) {
                let branches = '';
                _.forEach(data, (branchId) => {
                  if (_.isObjectLike(branchId)) {
                    branches += branchId.name + ', ';
                  } else {
                    let userBranch = _.find(this.getBranches, (branch) => {
                      return branch.number === branchId;
                    });
                    if (userBranch && userBranch.name) {
                      branches += userBranch.name + ', ';
                    }
                  }
                });
                if (branches !== '') {
                  branches = branches.substring(0, branches.length - 2);
                }
                return branches;
              } else {
                return '';
              }
            },
            targets: 7
          },
          {
            render: (data) => {
              if (data && data.length > 0) {
                let products = '';
                _.forEach(data, (productId) => {
                  if (_.isObjectLike(productId)) {
                    products += productId.name + ', ';
                  } else {
                    let userProduct = _.find(this.getProducts, (product) => {
                      return product.id === productId;
                    });
                    if (userProduct && userProduct.name) {
                      products += userProduct.name + ', ';
                    }
                  }
                });
                if (products !== '') {
                  products = products.substring(0, products.length - 2);
                }
                return products;
              } else {
                return '';
              }
            },
            targets: 8
          },
          {
            render: (data) => {
              if (data) {
                return moment(data.toLocaleString()).format('dddd, MMMM Do YYYY h:mm A');
              } else {
                return "";
              }
            },
            targets: 9
          },
          {
            render: (data) => {
              return data === "" ? "" : (data) ? "Yes" : "No";
            },
            targets: 10
          }
        ],
        buttons: [
          {
            extend: 'pageLength',
            text: '<i class="fas fa-filter"></i>',
            titleAttr: 'Number of Rows'
          },
          {
            extend: 'copyHtml5',
            text: '<i class="far fa-copy"></i>',
            titleAttr: 'Copy',
            exportOptions: {
              columns: ':visible'
            }
          },
          {
            extend: 'excelHtml5',
            autoFilter: true,
            text: '<i class="far fa-file-excel"></i>',
            titleAttr: 'Excel',
            exportOptions: {
              columns: ':visible'
            }
          },
          {
            extend: 'csvHtml5',
            text: '<i class="far fa-file-alt"></i>',
            titleAttr: 'CSV',
            exportOptions: {
              columns: ':visible'
            }
          },
          // {
          // 	extend: 'pdfHtml5',
          // 	text: '<i class="far fa-file-pdf"></i>',
          // 	titleAttr: 'PDF',
          // 	orientation: 'landscape',
          // 	pageSize: 'LEGAL',
          // 	exportOptions: {
          // 		columns: ':visible'
          // 	},
          // 	customize: (doc) => {
          // 		for (let i = 1; i < doc.content[1].table.body.length; i++) {
          // 			let imageString = _.find(this.users, (user) => {
          // 				return user.username === doc.content[1].table.body[i][1].text
          // 			}).picture;
          //
          // 			if (imageString && imageString !== '') {
          // 				doc.content[1].table.body[i][0] = {
          // 					margin: [0, 0, 0, 12],
          // 					alignment: 'center',
          // 					image: imageString,
          // 					width: 30,
          // 					height: 30
          // 				};
          // 			}
          // 		}
          // 	}
          // },
          {
            extend: 'print',
            text: '<i class="fa fa-print"></i>',
            titleAttr: 'Print',
            exportOptions: {
              columns: ':visible',
              stripHtml: false
            },
            customize: (win) => {
              let css = '@page { size: landscape; }',
                  head = win.document.head || win.document.getElementsByTagName('head')[0],
                  style = win.document.createElement('style');

              style.type = 'text/css';
              style.media = 'print';

              style.appendChild(win.document.createTextNode(css));

              head.appendChild(style);
            }
          },
          {
            extend: 'colvis',
            text: '<i class="fa fa-eye"></i>',
            titleAttr: 'Column Visibility',
            columns: ':not(.noVis)'
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        order: [[9, "desc"]],
        rowReorder: true
      });

      this.userTable.buttons()
          .container()
          .appendTo('#usersList_wrapper .col-sm-6:eq(0)');

      this.setupUserModal();
    },
    setupUserModal: function () {
      $('#usersList tbody').on('click', 'tr', (e) => {
        this.userDetails = {};
        this.userDetails = _.cloneDeep(this.userTable.row(e.currentTarget).data());
        if (this.userDetails) {
          if (!_.isObjectLike(this.userDetails.jobTitle)) {
            let userJobTitle = _.find(this.getTitles, (title) => {
              return title.id === this.userDetails.jobTitle;
            });

            if (userJobTitle) {
              this.userDetails.jobTitle = userJobTitle;
            } else {
              this.userDetails.jobTitle = '';
            }
          }

          let userBranches = [];
          _.forEach(this.userDetails.branchId, (branchId) => {
            if (_.isObjectLike(branchId)) {
              userBranches.push(branchId);
            } else {
              let userBranch = _.find(this.getBranches, (branch) => {
                return branch.number === branchId;
              });
              if (userBranch) {
                userBranches.push(userBranch);
              }
            }
          });
          this.userDetails.branchId = userBranches;

          let userProducts = [];
          _.forEach(this.userDetails.products, (productId) => {
            if (_.isObjectLike(productId)) {
              this.userDetails.products = productId;
            } else {
              let userProduct = _.find(this.getProducts, (product) => {
                return product.id === productId;
              });
              if (userProduct) {
                userProducts.push(userProduct);
              }
            }
          });
          this.userDetails.products = userProducts;

          if (!_.isObjectLike(this.userDetails.supervisor)) {
            let userSupervisor = _.find(this.users, (title) => {
              return title.id === this.userDetails.supervisor;
            });

            if (userSupervisor) {
              this.userDetails.supervisor = userSupervisor;
            } else {
              this.userDetails.supervisor = '';
            }
          }

          if (this.userDetails.birthDate && this.userDetails.birthDate) {
            this.userDetails.birthDate = moment(this.userDetails.birthDate, "YYYY-MM-DD").format('DD-MM-YYYY');
          }

          $("#userDetailsModal").modal('show');
        } else {
          this.userDetails = {};
        }
      });
    },
    async updateUserGroup(username, group, jobTitle, users, updateType) {
      try {
        if (updateType === 'add') {
          let {
            error
          } = await API.post("AdminQueries", '/addUserToGroup', {
            body: {
              username: username,
              groupname: group,
              jobTitle: jobTitle,
              users: users
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
          });
          if (error) {
            this.setMessage('v-notify-error', "Something went wrong!", error);
          }
        } else {
          let {
            error
          } = await API.post("AdminQueries", '/removeUserFromGroup', {
            body: {
              username: username,
              groupname: group,
              jobTitle: jobTitle,
              users: users
            },
            headers: {
              Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
          });
          if (error) {
            this.setMessage('v-notify-error', "Something went wrong!", error);
          }
        }
      } catch (err) {
        this.setMessage('v-notify-error', "Something went wrong!", err);
      }
    },
    async updateCurrentUser() {
      const updateButton = $('#updateButton');
      updateButton.attr('disabled', true);
      let updateUser = {
        id: this.userDetails.id
      };

      if (updateUser.id === '') {
        this.setMessage('v-notify-error', "Sorry!", 'Unable to update this user at this time!');
        updateButton.attr('disabled', false);
        return;
      }

      if (this.userDetails.jobTitle && this.userDetails.jobTitle.id !== '') {
        updateUser.jobTitle = this.userDetails.jobTitle.id;
      } else {
        this.setMessage('v-notify-error', "Error!", 'No Job Title Selected!');
        updateButton.attr('disabled', false);
        return;
      }

      if (this.userDetails.supervisor && this.userDetails.supervisor.id !== '') {
        if (this.userDetails.supervisor.id === updateUser.id) {
          this.setMessage('v-notify-error', "Error!", 'The user cannot be their own supervisor!');
          updateButton.attr('disabled', false);
          return;
        } else {
          updateUser.supervisor = this.userDetails.supervisor.id;
        }
      } else {
        this.setMessage('v-notify-error', "Error!", 'No Supervisor Selected!');
        updateButton.attr('disabled', false);
        return;
      }

      if (this.userDetails.branchId && this.userDetails.branchId.length > 0) {
        updateUser.branchId = _.map(this.userDetails.branchId, 'number');
      } else {
        this.setMessage('v-notify-error', "Error!", 'No Branch Selected!');
        updateButton.attr('disabled', false);
        return;
      }
      if (this.userDetails.products && this.userDetails.products.length > 0) {
        updateUser.products = _.map(this.userDetails.products, 'id');
      } else {
        updateUser.products = null;
      }
      try {
        const {data} = await API.graphql(graphqlOperation(mutations.updateUser, {
          input: updateUser
        }));
        this.userDetails = data.updateUser;

        let clonedTitles = _.cloneDeep(this.getTitles);

        let oldJobTitle = _.cloneDeep(this.userTable.row("#" + this.userDetails.id).data()).jobTitle;
        let newJobTitle = _.find(clonedTitles, (title) => {
          return title.id === updateUser.jobTitle;
        });

        if (oldJobTitle && oldJobTitle !== '') {
          if (_.isObjectLike(oldJobTitle)) {
            oldJobTitle = _.find(clonedTitles, (title) => {
              return title.id === oldJobTitle.id;
            });
          } else {
            oldJobTitle = _.find(clonedTitles, (title) => {
              return title.id === oldJobTitle;
            });
          }
          if (oldJobTitle && oldJobTitle.id !== newJobTitle.id) {
            _.remove(oldJobTitle.users, (user) => {
              return user === updateUser.id;
            });
            this.updateUserGroup(this.userDetails.username, oldJobTitle.access, oldJobTitle.id, oldJobTitle.users, 'remove');
          }
        }
        let userInGroup = _.find(newJobTitle.users, (user) => {
          return user === updateUser.id;
        });
        if (!userInGroup || userInGroup === '') {
          if (newJobTitle.users && newJobTitle.users.length > 0) {
            newJobTitle.users.push(updateUser.id);
          } else {
            newJobTitle.users = [updateUser.id];
          }
          this.updateUserGroup(this.userDetails.username, newJobTitle.access, newJobTitle.id, newJobTitle.users, 'add');
        }
        updateButton.attr('disabled', false);
        this.setMessage('v-notify-success', "Success!", "The user was updated successfully!");
        $("#userDetailsModal").modal('hide');
        this.userTable.row("#" + this.userDetails.id)
            .invalidate()
            .data(this.userDetails)
            .draw();
      } catch (e) {
        this.setMessage('v-notify-error', "Error!", e);
        updateButton.attr('disabled', false);
      }
    },
    async enableCurrentUser() {
      const enableButton = $('#enableButton');
      enableButton.attr('disabled', true);
      try {
        const response = await API.post("AdminQueries", '/enableUser', {
          body: {
            username: this.userDetails.username
          },
          headers: {
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          }
        });
        this.userDetails = response;
        this.setMessage('v-notify-success', "Success!", this.userDetails.username + '\'s account is now enabled!');
        enableButton.attr('disabled', false);
        $("#userDetailsModal").modal('hide');
        this.userTable.row("#" + this.userDetails.id)
            .invalidate()
            .data(this.userDetails)
            .draw();
      } catch (err) {
        this.setMessage('v-notify-error', "Something went wrong!", err);
        enableButton.attr('disabled', false);
      }
    },
    async disableCurrentUser() {
      const disableButton = $('#disableButton');
      disableButton.attr('disabled', true);
      try {
        const response = await API.post("AdminQueries", '/disableUser', {
          body: {
            username: this.userDetails.username
          },
          headers: {
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          }
        });
        this.userDetails = response;
        this.setMessage('v-notify-success', "Success!", this.userDetails.username + '\'s account is now disabled!');
        disableButton.attr('disabled', false);
        $("#userDetailsModal").modal('hide');
        this.userTable.row("#" + this.userDetails.id)
            .invalidate()
            .data(this.userDetails)
            .draw();
      } catch (err) {
        this.setMessage('v-notify-error', "Something went wrong!", err);
        disableButton.attr('disabled', false);
      }
    }
  }
}
</script>

<style scoped>

</style>
